
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "course-catalog",
  components: {
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    store.dispatch("getAllCourses");
    const courses = computed(() => store.getters.getCourseData);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    return {
      user,
      courses,
      router,
      currentLanguage
    };
  },
  methods: {
    openCourse(id) {
      this.$router.push({
        name: "training-course",
        params: {
          course_id: id
        }
      });
    }
  }
});
